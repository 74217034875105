import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SpinnerModule } from '../../admin/spinner/spinner.module';
import { EditarSuscripcionComponent } from './editar-suscripcion.component';

@NgModule({
  declarations: [EditarSuscripcionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    CKEditorModule,
    SpinnerModule
  ]
})
export class EditarSuscripcionModule { }
