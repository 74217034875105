import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SpinnerModule } from '../../admin/spinner/spinner.module';
import { EditarCategoriaIngredienteComponent } from './editar-categoria-ingrediente.component';

@NgModule({
  declarations: [EditarCategoriaIngredienteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    SpinnerModule
  ]
})
export class EditarCategoriaIngredienteModule { }
