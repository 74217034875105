import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { PasoElaboracion } from '../../../shared/modelos/pasoelaboracion.modelo';
import { ElaboracionService } from '../elaboracion.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-paso',
  templateUrl: './editar-paso.component.html',
  styleUrls: ['./editar-paso.component.scss']
})
export class EditarPasoComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public paso: PasoElaboracion;
  public idReceta: string;
  public bQuitarImagen = false;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarPasoComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private elabSvc: ElaboracionService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.paso = data.paso;
    this.idReceta = data.idReceta;
  }

  public editPasoForm = new FormGroup({
    id: new FormControl(''),
    texto: new FormControl('', [Validators.required, Validators.minLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.paso.imagen;
      this.imageOriginal = this.paso.imagen;
    }

    this.cargarValoresInicialesFormulario(this.paso);
  }

  onQuitarImagen(): void {
    this.bQuitarImagen = true;
    this.image = null;
    this.imagePreview = null;
  }

  savePaso(event): void {
    if (!this.editPasoForm.valid) {
      return;
    }

    const paso = this.editPasoForm.value;

    this.globals.loading = true;

    if (this.image === this.imageOriginal) {
      paso.imagen = this.imageOriginal;
      this.elabSvc.update(this.idReceta, paso)
      .then(() => {
        this.sbSvc.success('Paso de elaboración actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar el paso de elaboración', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      this.elabSvc.updateWithUpload(this.idReceta, paso, this.image)
      .then(() => {
        this.sbSvc.success('Paso de elaboración actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar el paso de elaboración', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(paso?: PasoElaboracion): void {
    if (paso) {
      this.editPasoForm.patchValue({
        id: paso.id,
        texto: paso.texto
      });
    } else {
      this.editPasoForm.patchValue({
        texto: ''
      });
    }
  }

  get texto() { return this.editPasoForm.get('texto'); }
}
