import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { CategoriaPost } from '../../shared/modelos/categoriapost.modelo';
import { DbService } from '../../shared/servicios/db.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriaPostService extends DbService<CategoriaPost> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('categorias_post', afs, storage);
  }

  public getTituloCategoriaPost(idCategoriaPost: string): Promise<string> {
    return this.afs.collection<CategoriaPost>('categorias_post').doc(idCategoriaPost)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            return data.titulo;
          }
        }),
        take(1)
      ).toPromise();
  }

  private getNumPostsByCategoria(idCategoria: string): Promise<number> {
    const coll = this.afs.collection('posts', ref =>
      ref.where('idCategoria', '==', idCategoria)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items =>
        items.length
      ),
      take(1)
    ).toPromise();
  }

  // Método delete con validación
  deleteValid(categoriaPost: CategoriaPost): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.getNumPostsByCategoria(categoriaPost.id)
      .then(numPosts => {
        if (numPosts === 0) {
          super.delete(categoriaPost)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        } else {
          reject('Imposible eliminar una categoría de post que tiene posts asignados');
        }
      })
      .catch(err => {
        reject(err);
      });
    });
    return promise;
  }
}
