import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Receta } from '../../shared/modelos/receta.modelo';
import { DbService } from '../../shared/servicios/db.service';
import * as firebase from 'firebase';
import { map, take } from 'rxjs/operators';
import { CategoriaReceta } from 'src/app/shared/modelos/categoriareceta.modelo';
import { FileI } from 'src/app/shared/modelos/file.interface';
import { CategoriaRecetaService } from '../categorias-receta/categoriareceta.service';
import { PackService } from '../packs/pack.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecetaService extends DbService<Receta> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage, public catRecetaSvc: CategoriaRecetaService,
              public packSvc: PackService) {
    super('recetas', afs, storage);
  }

  getAllPrivadas(): Observable<Receta[]> {
    const coll = this.afs.collection('recetas', ref =>
      ref.where('nivelAcceso', '>', 1)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Receta;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
  }

  async addWithUpload(item: Receta, imagen?: FileI): Promise<Receta> {
    // 1º Si hay imagen, la subimos
    if (imagen) {
      item.imagen = await this.uploadImagen(imagen);
    }

    // 2º obtenemos el título de la categoria
    item.tituloCategoria = await this.catRecetaSvc.getTituloCategoriaReceta(item.idCategoria);

    return this.add(item);
  }

  // Método para añadir un elemento (sin upload)
  add(item: Receta): Promise<Receta> {
    // Eliminamos el id del item de sus propiedades
    delete item.id;
    const newId = this.afs.createId();
    const promise = new Promise<Receta>((resolve, reject) => {
      item.idReceta = newId;
      this.coleccion.doc(newId).set({
        ...(item as any)
      });

      resolve(item);
    });

    return promise;
  }

  updateResumen(receta: Receta): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const refRecetaActual = this.afs.collection('recetas').doc(receta.id).ref;
      refRecetaActual
      .update({ resumen: receta.resumen })
      .then(() => {
        resolve(true);
      })
      .catch(err => {
        reject(err);
      });
  });

  return promise;
    /*const promise = new Promise<boolean>((resolve, reject) => {
      let batch = this.afs.firestore.batch();

      return this.afs.collection("recetas").ref.get()
      .then(docs => {
        docs.forEach(doc => {
          const data = doc.data() as Receta;
          batch.update(doc.ref, { 'resumen': data.titulo });
        })

        batch.commit().catch(err => console.error(err));
      });
    });

    return promise;*/
  }

  async update(item: Receta, imagen?: FileI, actualizarImagen: boolean = true): Promise<Receta> {
    // 1º Si hay imagen, la subimos
    if (actualizarImagen) {
      item.imagen = await this.uploadImagen(imagen);
    }

    // 2º obtenemos el título de la categoria
    item.tituloCategoria = await this.catRecetaSvc.getTituloCategoriaReceta(item.idCategoria);

    // 3º Realizamos el update
    return super.update(item);
  }

  updateRecetasPack(idPack: string, recetas: Receta[]): Promise<boolean> {
    const promise = new Promise<boolean>(async (resolve, reject) => {
      // 1º Obtenemos el pack actual con sus recetas
      const pack = await this.packSvc.getOneSimple(idPack);

      // Iniciamos el batch
      const batch = this.afs.firestore.batch();
      if (pack.recetas != null) {
        const listaIdsRecetas = Object.keys(pack.recetas);
        // 2º Actualizamos el idPack de cada receta actual a null
        listaIdsRecetas.forEach(idReceta => {
          const refReceta = this.afs.collection('recetas').doc(idReceta).ref;
          batch.update(refReceta, { idPack: firebase.firestore.FieldValue.delete() });
        });

        // 3º Quitamos el map de recetas del pack
        const refPackDelete = this.afs.collection('packs').doc(idPack).ref;
        batch.update(refPackDelete, { recetas: firebase.firestore.FieldValue.delete() });
      }

      // 4º Agregamos el mapa de recetas en el pack
      const refPack = this.afs.collection('packs').doc(idPack).ref;
      const recetasPackMap = {};
      recetas.forEach(receta => {
        recetasPackMap[`recetas.${receta.id}`] = true;
      });

      const refPackActual = this.afs.collection('packs').doc(idPack).ref;

      batch.update(refPackActual, recetasPackMap);

      // 5º Agregamos el id del pack en cada una de las recetas
      recetas.forEach(receta => {
        const refReceta = this.afs.collection('recetas').doc(receta.id).ref;
        batch.update(refReceta, { idPack: idPack });
      });

      batch.commit()
      .then(() => {
        // Devolvemos el ingrediente con su id
        resolve(true);
      })
      .catch(err => {
         console.error(err);
         reject(err);
       });
    });

    return promise;
  }

  updateDestacada(receta: Receta): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();

      // 1.- Obtenemos la receta destacada actual (si existe)
      this.getIdRecetaDestacadaPromise()
      .then(idRecetaDestacada => {
        // 2.- Si existe, la ponemos como no destacada
        if (idRecetaDestacada != null) {
          const refRecetaDestacadaActual = this.afs.collection('recetas').doc(idRecetaDestacada).ref;
          batch.update(refRecetaDestacadaActual, { destacada: false });
        }

        // 3.- Añadimos la receta que se pasa por parámetro como la receta actual
        const refRecetaDestacadaNueva = this.afs.collection('recetas').doc(receta.id).ref;
        batch.update(refRecetaDestacadaNueva, { destacada: true });

        batch.commit()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
      })
      .catch(err => {
        reject(err);
      });
    });

    return promise;
  }

  delete(receta: Receta): Promise<void> {
    return this.deleteAtPath(`recetas/${receta.id}`)
    .then(result => {
      console.log('Delete success: ' + JSON.stringify(result));
    })
    .catch(err => {
        console.log('Delete failed, see console,');
        console.warn(err);
    });
  }

  deleteAtPath(ruta: string) {
    const deleteFn = firebase.functions().httpsCallable('eliminar_receta');
    return deleteFn({ path: ruta });
  }

  private getIdRecetaDestacadaPromise(): Promise<string> {
    const coll = this.afs.collection<Receta>('recetas', ref =>
      ref.where('destacada', '==', true)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items => {
        if (!items || items.length === 0) {
          return null;
        } else {
          return items[0].payload.doc.id;
        }
      }),
      take(1)
    ).toPromise();
  }
}
