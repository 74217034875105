<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container">
            <form [formGroup]="editEventoForm" (ngSubmit)="saveEvento($event)">
                <div class="container-item">
                    <div class="container-item-1">
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Título</mat-label>
                            <input matInput placeholder="Título del evento" formControlName="titulo" />
                            <div class="alert alert-danger" *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)">
                                <div class="container-error-message" *ngIf="titulo.errors.required">
                                    El título es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="titulo.errors.minlength">
                                    El título debe tener, al menos, 10 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Fecha</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="fecha">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Horas</mat-label>
                            <input matInput placeholder="Horas del evento" formControlName="horas" />
                            <div class="alert alert-danger" *ngIf="horas.invalid && (horas.dirty || horas.touched)">
                                <div class="container-error-message" *ngIf="horas.errors.required">
                                    El campo horas es requerido.
                                </div>
                            </div>
                        </mat-form-field>                     
                        <mat-form-field class="mat-form-field-half">
                            <mat-label>Lugar</mat-label>
                            <input matInput placeholder="Lugar del evento" formControlName="lugar" />
                            <div class="alert alert-danger" *ngIf="lugar.invalid && (lugar.dirty || lugar.touched)">
                                <div class="container-error-message" *ngIf="lugar.errors.required">
                                    El lugar es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-half">
                            <mat-label>Ubicación</mat-label>
                            <input matInput placeholder="Ubicación del evento" formControlName="ubicacion" />
                            <div class="alert alert-danger" *ngIf="ubicacion.invalid && (ubicacion.dirty || ubicacion.touched)">
                                <div class="container-error-message" *ngIf="ubicacion.errors.required">
                                    El campo ubicacion es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="container-item-2">
                        <div class="label bottom">
                            <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                            <div class="contenedor-imagen-item">
                                <img [src]="imagePreview ? imagePreview : image" (click)="inputImageEvento.click()" *ngIf="imagePreview || image" />
                                <img src="../../../../assets/no_imagen.jpg" (click)="inputImageEvento.click()" *ngIf="!(imagePreview || image)" />
                            </div>
                            <input type="file" #inputImageEvento formControlName="imagen" (change)="handleImagen($event)" />
                        </div>
                    </div>
                </div>
                <div class="mat-form-field-full">
                    <div class="editor-contenido">
                        <ckeditor [editor]="Editor" [config]="configCKEditor" formControlName="contenido"></ckeditor>
                        <div class="alert alert-danger" *ngIf="contenido.invalid && (contenido.dirty || contenido.touched)">
                            <div class="container-error-message" *ngIf="contenido.errors.required">
                                El contenido es requerido.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editEventoForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>
