<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container">
            <form [formGroup]="editLibroForm" (ngSubmit)="saveLibro($event)">
                <div class="container-item">
                    <div class="container-item-1">
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Título</mat-label>
                            <input matInput placeholder="Título del libro" formControlName="titulo" />
                            <div class="alert alert-danger" *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)">
                                <div class="container-error-message" *ngIf="titulo.errors.required">
                                    El título es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="titulo.errors.minlength">
                                    El título debe tener, al menos, 10 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Enlace</mat-label>
                            <input matInput placeholder="Enlace del libro" formControlName="enlace" />
                            <div class="alert alert-danger" *ngIf="enlace.invalid && (enlace.dirty || enlace.touched)">
                                <div class="container-error-message" *ngIf="enlace.errors.required">
                                    El enlace es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="enlace.errors.pattern">
                                    El enlace no es una URL válida.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Precio</mat-label>
                            <input matInput placeholder="Precio del libro" formControlName="precio" />
                            <div class="alert alert-danger" *ngIf="precio.invalid && (precio.dirty || precio.touched)">
                                <div class="container-error-message" *ngIf="precio.errors.required">
                                    El precio es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="precio.errors.maxlength">
                                    El precio no puede ser mayor de 10.000€.
                                </div>
                                <div class="container-error-message" *ngIf="precio.errors.pattern">
                                    El campo precio no puede tener más de 7 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Orden</mat-label>
                            <input matInput placeholder="Orden del libro" formControlName="orden" />
                            <div class="alert alert-danger" *ngIf="orden.invalid && (orden.dirty || orden.touched)">
                                <div class="container-error-message" *ngIf="orden.errors.required">
                                    El campo orden es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="orden.errors.pattern">
                                    El campo orden debe ser numérico sin decimales.
                                </div>
                                <div class="alert alert-danger" *ngIf="orden.invalid && (orden.dirty || orden.touched)">
                                    <div class="container-error-message" *ngIf="orden.errors.maxlength">
                                        El campo orden no puede ser mayor que 999.
                                    </div>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="container-item-2">
                        <div class="label bottom">
                            <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                            <div class="contenedor-imagen-item">
                                <img [src]="imagePreview ? imagePreview : image" (click)="inputImageLibro.click()" *ngIf="imagePreview || image" />
                                <img src="../../../../assets/no_imagen.jpg" (click)="inputImageLibro.click()" *ngIf="!(imagePreview || image)" />
                            </div>
                            <input type="file" #inputImageLibro formControlName="imagen" (change)="handleImagen($event)" />
                        </div>
                    </div>
                </div>
                <div class="mat-form-field-full">
                    <div class="editor-contenido">
                        <ckeditor [editor]="Editor" [config]="configCKEditor" formControlName="contenido"></ckeditor>
                        <div class="alert alert-danger" *ngIf="contenido.invalid && (contenido.dirty || contenido.touched)">
                            <div class="container-error-message" *ngIf="contenido.errors.required">
                                El contenido es requerido.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editLibroForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>
