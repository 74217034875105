import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Ingrediente } from '../../shared/modelos/ingrediente.modelo';
import { IngredienteReceta } from '../../shared/modelos/ingredientereceta.modelo';
import { DbService } from '../../shared/servicios/db.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FileI } from 'src/app/shared/modelos/file.interface';
import * as firebase from 'firebase/app';
import { RecetaService } from '../recetas/receta.service';

@Injectable({
  providedIn: 'root'
})
export class IngredienteService extends DbService<Ingrediente> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('ingredientes', afs, storage);
  }

  getAllItemsByReceta(idReceta: string): Observable<any[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('ingredientes', ref => ref.orderBy('orden'))
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  // Método para añadir un elemento (con upload)
  addWithUpload(ingrediente: Ingrediente, imagen?: FileI): Promise<Ingrediente> {
    let idIngrediente = '';

    const promise = new Promise<Ingrediente>(async (resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();

      // 1º Si hay imagen, la añadimos
      if (imagen) {
        ingrediente.imagen = await this.uploadImagen(imagen);
      }

      // 2º Agregamos el item
      delete ingrediente.id;
      idIngrediente = this.afs.createId();
      const refNuevo = this.afs.collection('ingredientes').doc<Ingrediente>(idIngrediente).ref;
      batch.set(refNuevo, ingrediente);

      // 3º Actualizamos la categoría del ingrediente
      const refCategoria = this.afs.collection('categorias_ingrediente').doc(ingrediente.idCategoria).ref;
      const ingredienteMap = {};
      ingredienteMap[`mapIngredientes.${idIngrediente}`] = {
        nombre: ingrediente.nombre,
        imagen: ingrediente.imagen
      };
      batch.update(refCategoria, ingredienteMap);

      // 4º Añadimos el ingrediente al listado de nombres para evitar duplicados en el futuro
      const refIndiceIngrediente = this.afs.collection('indice_ingredientes').doc(ingrediente.nombre.toUpperCase()).ref;
      batch.set(refIndiceIngrediente, { id: idIngrediente });

      batch.commit()
      .then(() => {
        // Devolvemos el ingrediente con su id
        resolve({
          id: idIngrediente,
          ...(ingrediente as any)
        });
      })
      .catch(err => {
        reject(err);
      });
    });

    return promise;
  }

  update(ingrediente: Ingrediente, imagen?: FileI, actualizarImagen: boolean = true): Promise<Ingrediente> {
    const promise = new Promise<Ingrediente>(async (resolve, reject) => {
      // 1º Si hay imagen, la subimos
      if (actualizarImagen) {
        ingrediente.imagen = await this.uploadImagen(imagen);
      }

      // 2º Obtenemos el ingrediente actual
      this.getOneSimple(ingrediente.id)
      .then(async ingredienteActual => {
        // Iniciamos el batch
        const batch = this.afs.firestore.batch();

        // 2º Eliminamos el ingrediente de la categoria
        const refCategoriaActual = this.afs.collection('categorias_ingrediente').doc(ingredienteActual.idCategoria).ref;
        const ingredienteActualMap = {};
        ingredienteActualMap[`mapIngredientes.${ingrediente.id}`] = firebase.firestore.FieldValue.delete();
        batch.update(refCategoriaActual, ingredienteActualMap);

        // 3º Agregamos el ingrediente en la nueva categoria
        const refCategoria = this.afs.collection('categorias_ingrediente').doc(ingrediente.idCategoria).ref;
        const ingredienteMap = {};
        ingredienteMap[`mapIngredientes.${ingrediente.id}`] = {
          nombre: ingrediente.nombre,
          imagen: ingrediente.imagen
        };
        batch.update(refCategoria, ingredienteMap);

        // 4º Modificamos el ingrediente
        const idIngrediente = ingrediente.id;
        const refIngrediente = this.afs.collection('ingredientes').doc<Ingrediente>(idIngrediente).ref;
        delete ingrediente.id;
        batch.update(refIngrediente, ingrediente);

        // 5ª Eliminamos el ingrediente actual del índice de nombres de ingrediente
        const refIndiceActual = this.afs.collection('indice_ingredientes').doc(ingredienteActual.nombre.toUpperCase()).ref;
        batch.delete(refIndiceActual);

        // 6º Añadimos el nuevo nombre de ingrediente al índice de nombres de ingrediente
        const refIndiceNuevo = this.afs.collection('indice_ingredientes').doc(ingrediente.nombre.toUpperCase()).ref;
        batch.set(refIndiceNuevo, { id: idIngrediente });

        batch.commit()
        .then(() => {
          // Devolvemos el ingrediente con su id
          resolve({
            id: idIngrediente,
            ...(ingrediente as any)
          });
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
      })
      .catch(err => {
        reject('Se produjo un error al actualizar el ingrediente');
      });
    });

    return promise;
  }


  getAllIdsIngredientes(): Promise<string[]> {
    return this.afs.collection('ingredientes')
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          return a.payload.doc.id;
        })
      ),
      take(1)
    ).toPromise();
  }

  /*temp() {
    const promise = new Promise<void>((resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();

      this.getAllIdsIngredientes()
      .then(idsIngrediente => {
        idsIngrediente.map(idIngrediente => {
          const refIngrediente = this.afs.collection('ingredientes').doc(idIngrediente).ref;
          batch.update(refIngrediente, { basico: false, despensa: firebase.firestore.FieldValue.delete() });
        });

        batch.commit()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
      });
    });

    return promise;
  }*/

  updateItemsReceta(idReceta: string, items: any[]): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();

      this.getAllIngredientesByRecetaPromise(idReceta)
      .then(idsIngredienteReceta => {
        // 1.- Eliminamos todos los ingredientes de la receta (subcolección)
        idsIngredienteReceta.map(idIngredienteReceta => {
          const refIngRecetaSub = this.afs.collection('recetas').doc(idReceta).collection('ingredientes').doc(idIngredienteReceta).ref;
          batch.delete(refIngRecetaSub);
        });

        // 2.- Borramos la lista de ingredientes de la receta (array)
        const refReceta = this.afs.collection('recetas').doc(idReceta).ref;
        batch.update(refReceta, { lista_ingredientes: firebase.firestore.FieldValue.delete() });

        // 3.- Agregamos todos los ingredientes en la subcolección y preparamos el array
        let refIngrediente = null;
        let id = null;
        let orden = 1;
        const listaIngredientes: string[] = [];

        for (const item of items) {
          id = this.afs.createId();
          refIngrediente = this.afs.collection('recetas').doc(idReceta).collection('ingredientes').doc(id).ref;
          const itemObject = Object.assign({}, item);
          itemObject.orden = orden;
          delete itemObject.id;
          batch.set(refIngrediente, itemObject);

          // Agregamos el ingrediente a la lista
          if (itemObject.tipo === 'ingrediente') {
            listaIngredientes.push(itemObject.idIngrediente);
          }

          orden++;
        }

        // 4.- Actualizamos la lista de ingredientes de la receta (array)
        if (listaIngredientes.length > 0) {
          batch.update(refReceta, { lista_ingredientes: listaIngredientes });
        }

        batch.commit()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
      });
    });

    return promise;
  }

  // Método delete con validación
  deleteValid(ingrediente: Ingrediente): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.getNumRecetasByIngrediente(ingrediente.id)
      .then(numRecetas => {
        if (numRecetas === 0) {
          this.delete(ingrediente)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        } else {
          reject('Imposible eliminar un ingrediente que está incluido en una o más recetas');
        }
      })
      .catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  delete(ingrediente: Ingrediente): Promise<void> {
    // Iniciamos el batch
    const batch = this.afs.firestore.batch();

    // 1º Eliminamos el ingrediente de la categoria
    const refCategoria = this.afs.collection('categorias_ingrediente').doc(ingrediente.idCategoria).ref;
    const ingredienteMap = {};
    ingredienteMap[`mapIngredientes.${ingrediente.id}`] = firebase.firestore.FieldValue.delete();
    batch.update(refCategoria, ingredienteMap);

    // 2º Eliminamos el ingrediente propiamente dicho
    const refIngrediente = this.coleccion.doc<Ingrediente>(ingrediente.id).ref;
    batch.delete(refIngrediente);

    // 3ª Eliminamos el ingrediente del índice de nombres de ingrediente
    const refIndiceNombreIngrediente = this.afs.collection('indice_ingredientes').doc(ingrediente.nombre.toUpperCase()).ref;
    batch.delete(refIndiceNombreIngrediente);

    return batch.commit();
  }

  /* Privados */
  private getAllIngredientesByRecetaPromise(idReceta: string): Promise<string[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('ingredientes')
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          return a.payload.doc.id;
        })
      ),
      take(1)
    ).toPromise();
  }

  private getNumRecetasByIngrediente(idIngrediente: string): Promise<number> {
    const coll = this.afs.collection('recetas', ref =>
      ref.where('lista_ingredientes', 'array-contains', idIngrediente)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items =>
        items.length
      ),
      take(1)
    ).toPromise();
  }
}
