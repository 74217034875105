import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { CategoriaIngrediente } from '../../shared/modelos/categoriaingrediente.modelo';
import { DbService } from '../../shared/servicios/db.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriaIngredienteService extends DbService<CategoriaIngrediente> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('categorias_ingrediente', afs, storage);
  }

  private getNumIngredientesByCategoria(idCategoria: string): Promise<number> {
    const coll = this.afs.collection('ingredientes', ref =>
      ref.where('idCategoria', '==', idCategoria)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items =>
        items.length
      ),
      take(1)
    ).toPromise();
  }

  // Método delete con validación
  deleteValid(categoriaIngrediente: CategoriaIngrediente): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.getNumIngredientesByCategoria(categoriaIngrediente.id)
      .then(numIngredientes => {
        if (numIngredientes === 0) {
          super.delete(categoriaIngrediente)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        } else {
          reject('Imposible eliminar una categoría de ingrediente que tiene ingredientes asignados');
        }
      })
      .catch(err => {
        reject(err);
      });
    });
    return promise;
  }
}
