import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Post } from '../../../shared/modelos/post.modelo';
import { CategoriaPost } from '../../../shared/modelos/categoriapost.modelo';
import { PostService } from '../post.service';
import { CategoriaPostService } from '../../categorias-post/categoriapost.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { firestore } from 'firebase';
import * as moment from 'moment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-post',
  templateUrl: './editar-post.component.html',
  styleUrls: ['./editar-post.component.scss']
})
export class EditarPostComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public categorias$: Observable<CategoriaPost[]>;
  public tiposVideo =  [{id: 'ninguno', nombre: 'Sin video'}, {id: 'privado', nombre: 'Privado (vimeo)'}, {id: 'publico', nombre: 'Público'}];
  public modo: string;
  public tituloForm: string;
  public post: Post;
  subscription: Subscription;
  public Editor = ClassicEditor;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarPostComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private postSvc: PostService, private catPostSvc: CategoriaPostService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.post = data.post;
  }

  public editPostForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    subtitulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    fecha: new FormControl('', [Validators.required]),
    contenido: new FormControl('', [Validators.required]),
    idCategoria: new FormControl('', [Validators.required]),
    imagen: new FormControl(''),
    tipoVideo: new FormControl(''),
    video: new FormControl('')
  });

  ngOnInit(): void {
    const controlTipoVideo = this.editPostForm.get('tipoVideo') as FormControl;
    const controlVideo = this.editPostForm.get('video') as FormControl;

    this.subscription = controlTipoVideo.valueChanges.subscribe(value => {
      if (value !== 'ninguno') {
        controlVideo.setValidators([Validators.required, Validators.pattern(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)]);
      } else {
        controlVideo.setValidators(null);
      }

      controlVideo.updateValueAndValidity();
    });

    this.categorias$ = this.catPostSvc.getAllOrden('titulo', 'asc');

    if (this.modo !== 'crear') {
      this.image = this.post.imagen;
      this.imageOriginal = this.post.imagen;
    }

    this.cargarValoresInicialesFormulario(this.post);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  savePost(event): void {
    if (!this.editPostForm.valid) {
      return;
    }

    const post = this.editPostForm.value;
    post.fecha = firestore.Timestamp.fromDate(moment(post.fecha, 'YYYY-MM-DDTHH:mm').toDate());
    if (post.tipoVideo === 'ninguno') {
      post.video = '';
    }

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.postSvc.addWithUpload(post, this.image)
      .then(() => {
        this.sbSvc.success('Post añadido correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir el post', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        post.imagen = this.imageOriginal;
      }

      this.postSvc.update(post, this.image, this.image !== this.imageOriginal)
      .then(() => {
        this.sbSvc.success('Post actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar el post', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(post?: Post): void {
    if (post) {
      this.editPostForm.patchValue({
        id: post.id,
        titulo: post.titulo,
        subtitulo: post.subtitulo,
        fecha: moment(post.fecha.toDate()).format('YYYY-MM-DDTHH:mm'),
        contenido: post.contenido,
        idCategoria: post.idCategoria,
        tipoVideo: post.tipoVideo,
        video: post.video
      });
    } else {
      this.editPostForm.patchValue({
        titulo: '',
        subtitulo: '',
        fecha: '',
        contenido: '',
        idCategoria: '',
        tipoVideo: 'ninguno',
        video: ''
      });
    }
  }

  get titulo() { return this.editPostForm.get('titulo'); }
  get subtitulo() { return this.editPostForm.get('subtitulo'); }
  get fecha() { return this.editPostForm.get('fecha'); }
  get idCategoria() { return this.editPostForm.get('idCategoria'); }
  get contenido() { return this.editPostForm.get('contenido'); }
  get tipoVideo() { return this.editPostForm.get('tipoVideo'); }
  get video() { return this.editPostForm.get('video'); }
}
