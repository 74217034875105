import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Suscripcion } from '../../shared/modelos/suscripcion.modelo';
import { DbService } from '../../shared/servicios/db.service';

@Injectable({
  providedIn: 'root'
})
export class SuscripcionService extends DbService<Suscripcion> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('suscripciones', afs, storage);
  }
}
