import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Pack } from '../../../shared/modelos/pack.modelo';
import { PackService } from '../pack.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-editar-pack',
  templateUrl: './editar-pack.component.html',
  styleUrls: ['./editar-pack.component.scss']
})
export class EditarPackComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public pack: Pack;
  public tiposVideo =  [{id: 'ninguno', nombre: 'Sin video'}, {id: 'privado', nombre: 'Privado (vimeo)'}, {id: 'publico', nombre: 'Público'}];
  public Editor = ClassicEditor;
  subscription: Subscription;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarPackComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private packSvc: PackService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.pack = data.pack;
  }

  public editPackForm = new FormGroup({
    id: new FormControl(''),
    codigo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    titulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    fechaLanzamiento: new FormControl('', [Validators.required]),
    contenido: new FormControl('', [Validators.required]),
    tipoVideo: new FormControl(''),
    video: new FormControl(''),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    const controlTipoVideo = this.editPackForm.get('tipoVideo') as FormControl;
    const controlVideo = this.editPackForm.get('video') as FormControl;

    this.subscription = controlTipoVideo.valueChanges.subscribe(value => {
      if (value !== 'ninguno') {
        controlVideo.setValidators([Validators.required, Validators.pattern(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)]);
      } else {
        controlVideo.setValidators(null);
      }

      controlVideo.updateValueAndValidity();
    });

    if (this.modo !== 'crear') {
      this.image = this.pack.imagen;
      this.imageOriginal = this.pack.imagen;
    }

    this.cargarValoresInicialesFormulario(this.pack);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  savePack(event): void {
    if (!this.editPackForm.valid) {
      return;
    }

    const pack = this.editPackForm.value;

    if (pack.tipoVideo !== 'ninguno' && pack.video === '') {
      Swal.fire('Error!', 'Debe agregar una URL de video o marcar que el pack no tiene video', 'error');
      return;
    }

    if (pack.tipoVideo === 'ninguno') {
      pack.video = '';
    }

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.packSvc.addWithUpload(pack, this.image)
      .then(() => {
        this.sbSvc.success('Pack añadido correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir el pack', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        pack.imagen = this.imageOriginal;
        this.packSvc.update(pack)
        .then(() => {
          this.sbSvc.success('Pack actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el pack', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.packSvc.updateWithUpload(pack, this.image)
        .then(() => {
          this.sbSvc.success('Pack actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el pack', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(pack?: Pack): void {
    if (pack) {
      this.editPackForm.patchValue({
        id: pack.id,
        codigo: pack.codigo,
        titulo: pack.titulo,
        fechaLanzamiento: pack.fechaLanzamiento.toDate(),
        contenido: pack.contenido,
        tipoVideo: pack.tipoVideo,
        video: pack.video
      });
    } else {
      this.editPackForm.patchValue({
        codigo: '',
        titulo: '',
        fechaLanzamiento: '',
        contenido: '',
        tipoVideo: '',
        video: ''
      });
    }
  }

  get codigo() { return this.editPackForm.get('codigo'); }
  get titulo() { return this.editPackForm.get('titulo'); }
  get fechaLanzamiento() { return this.editPackForm.get('fechaLanzamiento'); }
  get contenido() { return this.editPackForm.get('contenido'); }
  get tipoVideo() { return this.editPackForm.get('tipoVideo'); }
  get video() { return this.editPackForm.get('video'); }
}
