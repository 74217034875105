import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ToolbarComponent } from './componentes/principal/toolbar/toolbar.component';

/* Firebase */
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';

import { environment } from 'src/environments/environment';

import { ReactiveFormsModule } from '@angular/forms';
import { ContenedorAppComponent } from './componentes/principal/contenedor-app/contenedor-app.component';
import { EditarPostModule } from './componentes/posts/editar-post/editar-post.module';
import { PostComponent } from './componentes/paginas/post/post.component';
import { EditarLibroModule } from './componentes/libros/editar-libro/editar-libro.module';
import { EditarEventoModule } from './componentes/eventos/editar-evento/editar-evento.module';
import { EditarCategoriaPostModule } from './componentes/categorias-post/editar-categoria-post/editar-categoria-post.module';
import { EditarIngredienteModule } from './componentes/ingredientes/editar-ingrediente/editar-ingrediente.module';
import { EditarCategoriaIngredienteModule } from './componentes/categorias-ingrediente/editar-categoria-ingrediente/editar-categoria-ingrediente.module';
import { EditarRecetaModule } from './componentes/recetas/editar-receta/editar-receta.module';
import { EditarCategoriaRecetaModule } from './componentes/categorias-receta/editar-categoria-receta/editar-categoria-receta.module';
import { EditarPackModule } from './componentes/packs/editar-pack/editar-pack.module';
import { EditarSuscripcionModule } from './componentes/suscripciones/editar-suscripcion/editar-suscripcion.module';
import { Pagina404Component } from './componentes/principal/pagina-404/pagina-404.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditarPasoModule } from './componentes/recetas/editar-paso/editar-paso.module';
import { EditarUtensilioModule } from './componentes/recetas/editar-utensilio/editar-utensilio.module';
import { Globals } from 'src/app/globals';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    ContenedorAppComponent,
    PostComponent,
    Pagina404Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    EditarPostModule,
    EditarLibroModule,
    EditarEventoModule,
    EditarCategoriaPostModule,
    EditarCategoriaIngredienteModule,
    EditarIngredienteModule,
    EditarRecetaModule,
    EditarCategoriaRecetaModule,
    EditarPasoModule,
    EditarUtensilioModule,
    EditarPackModule,
    EditarSuscripcionModule,
    CKEditorModule
  ],
  entryComponents: [],
  providers: [
    Globals,
    { provide: StorageBucket, useValue: environment.storageConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
