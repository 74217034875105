import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '../../admin/spinner/spinner.module';
import { EditarIngredienteComponent } from './editar-ingrediente.component';

@NgModule({
  declarations: [EditarIngredienteComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    CKEditorModule,
    SpinnerModule
  ]
})
export class EditarIngredienteModule { }
