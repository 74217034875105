import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from '../../posts/post.service';
import { Post } from '../../../shared/modelos/post.modelo';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public post$: Observable<Post>;

  constructor(private router: Router, private route: ActivatedRoute, private postSvc: PostService) { }

  ngOnInit(): void {
    const idPost = this.route.snapshot.params.id;
    this.post$ = this.postSvc.getOne(idPost);
  }

  volver(): void {
    this.router.navigate(['/home']);
  }
}
