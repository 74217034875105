import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { CategoriaReceta } from '../../../shared/modelos/categoriareceta.modelo';
import { CategoriaRecetaService } from '../categoriareceta.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-categoria-receta',
  templateUrl: './editar-categoria-receta.component.html',
  styleUrls: ['./editar-categoria-receta.component.scss']
})
export class EditarCategoriaRecetaComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public categoriaReceta: CategoriaReceta;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarCategoriaRecetaComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private catRecetaSvc: CategoriaRecetaService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.categoriaReceta = data.categoriaReceta;
  }

  public editCategoriaRecetaForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(3)]),
    orden: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.categoriaReceta.imagen;
      this.imageOriginal = this.categoriaReceta.imagen;
    }

    this.cargarValoresInicialesFormulario(this.categoriaReceta);
  }

  saveCategoriaReceta(event): void {
    if (!this.editCategoriaRecetaForm.valid) {
      return;
    }

    const categoriaReceta = this.editCategoriaRecetaForm.value;
    categoriaReceta.orden = +categoriaReceta.orden;

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.catRecetaSvc.addWithUpload(categoriaReceta, this.image)
      .then(() => {
        this.sbSvc.success('Categoría de receta añadida correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir la categoría de receta', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        categoriaReceta.imagen = this.imageOriginal;
      }

      this.catRecetaSvc.update(categoriaReceta, this.image, this.image !== this.imageOriginal)
      .then(() => {
        this.sbSvc.success('Categoría de receta actualizada correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar la categoría de receta', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(categoriaReceta?: CategoriaReceta): void {
    if (categoriaReceta) {
      this.editCategoriaRecetaForm.patchValue({
        id: categoriaReceta.id,
        titulo: categoriaReceta.titulo,
        orden: categoriaReceta.orden
      });
    } else {
      this.editCategoriaRecetaForm.patchValue({
        titulo: '',
        orden: 0
      });
    }
  }

  get titulo() { return this.editCategoriaRecetaForm.get('titulo'); }
  get orden() { return this.editCategoriaRecetaForm.get('orden'); }
}
