import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Evento } from '../../../shared/modelos/evento.modelo';
import { EventoService } from '../evento.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-evento',
  templateUrl: './editar-evento.component.html',
  styleUrls: ['./editar-evento.component.scss']
})
export class EditarEventoComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public evento: Evento;
  public Editor = ClassicEditor;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarEventoComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private evtSvc: EventoService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.evento = data.evento;
  }

  public editEventoForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    fecha: new FormControl('', [Validators.required]),
    horas: new FormControl('', [Validators.required]),
    lugar: new FormControl('', [Validators.required]),
    ubicacion: new FormControl('', [Validators.required]),
    contenido: new FormControl('', [Validators.required]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.evento.imagen;
      this.imageOriginal = this.evento.imagen;
    }

    this.cargarValoresInicialesFormulario(this.evento);
  }

  saveEvento(event): void {
    if (!this.editEventoForm.valid) {
      return;
    }

    const evento = this.editEventoForm.value;

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.evtSvc.addWithUpload(evento, this.image)
      .then(() => {
        this.sbSvc.success('Evento añadido correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir el evento', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        evento.imagen = this.imageOriginal;
        this.evtSvc.update(evento)
        .then(() => {
          this.sbSvc.success('Evento actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el evento', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.evtSvc.updateWithUpload(evento, this.image)
        .then(() => {
          this.sbSvc.success('Evento actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el evento', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(evento?: Evento): void {
    if (evento) {
      this.editEventoForm.patchValue({
        id: evento.id,
        titulo: evento.titulo,
        fecha: evento.fecha.toDate(),
        horas: evento.horas,
        lugar: evento.lugar,
        ubicacion: evento.ubicacion,
        contenido: evento.contenido,
      });
    } else {
      this.editEventoForm.patchValue({
        titulo: '',
        fecha: '',
        horas: '',
        lugar: '',
        ubicacion: '',
        contenido: ''
      });
    }
  }

  get titulo() { return this.editEventoForm.get('titulo'); }
  get fecha() { return this.editEventoForm.get('fecha'); }
  get horas() { return this.editEventoForm.get('horas'); }
  get lugar() { return this.editEventoForm.get('lugar'); }
  get ubicacion() { return this.editEventoForm.get('ubicacion'); }
  get contenido() { return this.editEventoForm.get('contenido'); }

}
