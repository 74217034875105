<mat-toolbar color="primary">
    <a routerLink="/">{{ appName }}</a>
    <span class="fill-remaining-space"></span>

    <a routerLink="/admin" routerLinkActive="active" *ngIf="authSvc.userData$ | async">
        <button mat-button>Admin</button>
    </a>

    <a *ngIf="authSvc.userData$ | async; else showLogin" (click)="onLogout()">
        <button mat-button>Salir</button>
    </a>

    <ng-template #showLogin>
        <a routerLink="/login" routerLinkActive="active">
            <button mat-button>Login</button>
        </a>
    </ng-template>
</mat-toolbar>
