<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container">
            <form [formGroup]="editIngredienteForm" (ngSubmit)="saveIngrediente($event)">
                <div class="container-item">
                    <div class="container-item-1">
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Nombre</mat-label>
                            <input matInput placeholder="Nombre del ingrediente" formControlName="nombre" />
                            <div class="alert alert-danger" *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
                                <div class="container-error-message" *ngIf="nombre.errors.required">
                                    El nombre es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="nombre.errors.minlength">
                                    El nombre debe tener, al menos, 3 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-select placeholder="Categoria" formControlName="idCategoria">
                                <mat-option *ngFor="let categoria of categorias$ | async" [value]="categoria.id">{{categoria.nombre}}</mat-option>
                            </mat-select>
                            <div class="alert alert-danger" *ngIf="idCategoria.invalid && (idCategoria.dirty || idCategoria.touched)">
                                <div class="container-error-message" *ngIf="idCategoria.errors.required">
                                    La categoría es requerida.
                                </div>
                            </div>
                        </mat-form-field>
                        <div class="checkbox-formulario">
                            <mat-checkbox class="example-margin" formControlName="basico">¿Es un ingrediente incluido en "Básicos"?</mat-checkbox>
                        </div>
                    </div>
                    <div class="container-item-2">
                        <div class="label bottom">
                            <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                            <div class="contenedor-imagen-item">
                                <img [src]="imagePreview ? imagePreview : image" (click)="inputImageIngrediente.click()" *ngIf="imagePreview || image" />
                                <img src="../../../../assets/no_imagen.jpg" (click)="inputImageIngrediente.click()" *ngIf="!(imagePreview || image)" />
                            </div>
                            <input type="file" #inputImageIngrediente formControlName="imagen" (change)="handleImagen($event)" />
                        </div>
                    </div>
                </div>
                <div class="mat-form-field-full">
                    <div class="editor-contenido">
                        <ckeditor [editor]="Editor" [config]="configCKEditor" formControlName="contenido"></ckeditor>
                        <div class="alert alert-danger" *ngIf="contenido.invalid && (contenido.dirty || contenido.touched)">
                            <div class="container-error-message" *ngIf="contenido.errors.required">
                                El contenido es requerido.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editIngredienteForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>
