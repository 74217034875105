// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// colandcol //
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD5QYn5efXEK9vUho-5Z_6HXVa6pLUtnXA',
    authDomain: 'colandcol-6ba5d.firebaseapp.com',
    databaseURL: 'https://colandcol-6ba5d.firebaseio.com',
    projectId: 'colandcol-6ba5d',
    storageBucket: 'colandcol-6ba5d.appspot.com',
    messagingSenderId: '551597762900',
    appId: '1:551597762900:web:d8a642ab628661f2d4c0c1',
    measurementId: 'G-HMFLBQ8NFZ'
  },
  storageConfig: 'gs://colandcol-6ba5d.appspot.com'
};

// jorgeeloy //
/*export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBSIA6ObfM9T4B3Tv_vIS6BZME0VXPhaHY',
    authDomain: 'aliterdulcia-c281e.firebaseapp.com',
    databaseURL: 'https://aliterdulcia-c281e.firebaseio.com',
    projectId: 'aliterdulcia-c281e',
    storageBucket: 'aliterdulcia-c281e.appspot.com',
    messagingSenderId: '507496657972',
    appId: '1:507496657972:web:d778ca2e234d5184658661',
    measurementId: 'G-1CSJTRG5BR'
  },
  storageConfig: 'gs://aliterdulcia-c281e.appspot.com'
};*/
