<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container-small">
            <form [formGroup]="editCategoriaPostForm" (ngSubmit)="saveCategoriaPost($event)">
                <mat-form-field class="mat-form-field-full">
                    <mat-label>Título</mat-label>
                    <input matInput placeholder="Título de la categoría de post" formControlName="titulo" />
                    <div class="alert alert-danger" *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)">
                        <div class="container-error-message" *ngIf="titulo.errors.required">
                            El título es requerido.
                        </div>
                        <div class="container-error-message" *ngIf="titulo.errors.minlength">
                            El título debe tener, al menos, 3 caracteres.
                        </div>
                    </div>
                </mat-form-field>
                <mat-form-field class="mat-form-field-full">
                    <mat-label>Orden</mat-label>
                    <input matInput placeholder="Orden de la categoría de post" formControlName="orden" />
                    <div class="alert alert-danger" *ngIf="orden.invalid && (orden.dirty || orden.touched)">
                        <div class="container-error-message" *ngIf="orden.errors.required">
                            El campo orden es requerido.
                        </div>
                        <div class="container-error-message" *ngIf="orden.errors.pattern">
                            El campo orden debe ser numérico sin decimales.
                        </div>
                        <div class="alert alert-danger" *ngIf="orden.invalid && (orden.dirty || orden.touched)">
                            <div class="container-error-message" *ngIf="orden.errors.maxlength">
                                El campo orden no puede ser mayor que 999.
                            </div>
                        </div>
                    </div>
                </mat-form-field>                
                <div class="container-item">
                    <div class="label bottom">
                        <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                        <div class="contenedor-imagen-item">
                            <img [src]="imagePreview ? imagePreview : image" (click)="inputImageCategoriaPost.click()" *ngIf="imagePreview || image" />
                            <img src="../../../../assets/no_imagen.jpg" (click)="inputImageCategoriaPost.click()" *ngIf="!(imagePreview || image)" />
                        </div>
                        <input type="file" #inputImageCategoriaPost formControlName="imagen" (change)="handleImagen($event)" />
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editCategoriaPostForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>