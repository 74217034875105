<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container-small">
            <form [formGroup]="editUtensilioForm" (ngSubmit)="saveUtensilio($event)">
                <mat-form-field class="mat-form-field-full">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Nombre del utensilio" formControlName="nombre" />
                    <div class="alert alert-danger" *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
                        <div class="container-error-message" *ngIf="nombre.errors.required">
                            El nombre es requerido.
                        </div>
                        <div class="container-error-message" *ngIf="nombre.errors.minlength">
                            El nombre debe tener, al menos, 3 caracteres.
                        </div>
                    </div>
                </mat-form-field>
                <div class="container-item">
                    <div class="label bottom">
                        <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                        <div class="contenedor-imagen-item">
                            <img [src]="imagePreview ? imagePreview : image" (click)="inputImageUtensilio.click()" *ngIf="imagePreview || image" />
                            <img src="../../../../assets/no_imagen.jpg" (click)="inputImageUtensilio.click()" *ngIf="!(imagePreview || image)" />
                        </div>
                        <input type="file" #inputImageUtensilio formControlName="imagen" (change)="handleImagen($event)" />
                    </div>
                </div>
                <div class="container-botones">
                    <button mat-raised-button color="warn" (click)="onQuitarImagen(); $event.preventDefault()">Quitar imagen</button>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editUtensilioForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>

