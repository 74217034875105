<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container">
            <form [formGroup]="editSuscripcionForm" (ngSubmit)="saveSuscripcion($event)">
                <div class="container-item">
                    <div class="container-item-1">
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Código</mat-label>
                            <input matInput placeholder="Código de la suscripción" formControlName="codigo" readonly/>
                            <div class="alert alert-danger" *ngIf="codigo.invalid && (codigo.dirty || codigo.touched)">
                                <div class="container-error-message" *ngIf="codigo.errors.required">
                                    El código es requerido.
                                </div>
                            </div>
                        </mat-form-field>                        
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Título</mat-label>
                            <input matInput placeholder="Título de la suscripción" formControlName="titulo" />
                            <div class="alert alert-danger" *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)">
                                <div class="container-error-message" *ngIf="titulo.errors.required">
                                    El título es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="titulo.errors.minlength">
                                    El título debe tener, al menos, 10 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Subtítulo</mat-label>
                            <input matInput placeholder="Subtítulo de la suscripción" formControlName="subtitulo" />
                            <div class="alert alert-danger" *ngIf="subtitulo.invalid && (subtitulo.dirty || subtitulo.touched)">
                                <div class="container-error-message" *ngIf="subtitulo.errors.required">
                                    El subtítulo es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="subtitulo.errors.minlength">
                                    El subtítulo debe tener, al menos, 10 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <div class="contenedor-inline">
                            <mat-form-field class="mat-form-field-one-third">
                                <mat-select placeholder="Tipo de video" formControlName="tipoVideo">
                                    <mat-option *ngFor="let tipoVideo of tiposVideo" [value]="tipoVideo.id">{{tipoVideo.nombre}}</mat-option>
                                </mat-select>
                                <div class="alert alert-danger" *ngIf="tipoVideo.invalid && (tipoVideo.dirty || tipoVideo.touched)">
                                    <div class="container-error-message" *ngIf="tipoVideo.errors.required">
                                        El campo tipo de video es requerido.
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-resto" *ngIf="tipoVideo.value && tipoVideo.value != 'ninguno'">
                                <mat-label>Video (URL)</mat-label>
                                <input matInput placeholder="Video de la receta" formControlName="video" />
                                <div class="alert alert-danger" *ngIf="video.invalid && (video.dirty || video.touched)">
                                    <div class="container-error-message" *ngIf="video.errors.pattern">
                                        La URL del video no es válida.
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>  
                    </div>
                    <div class="container-item-2">
                        <div class="label bottom">
                            <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                            <div class="contenedor-imagen-item">
                                <img [src]="imagePreview ? imagePreview : image" (click)="inputImageSuscripcion.click()" *ngIf="imagePreview || image" />
                                <img src="../../../../assets/no_imagen.jpg" (click)="inputImageSuscripcion.click()" *ngIf="!(imagePreview || image)" />
                            </div>
                            <input type="file" #inputImageSuscripcion formControlName="imagen" (change)="handleImagen($event)" />
                        </div>
                    </div>
                </div>
                <div class="mat-form-field-full">
                    <div class="editor-contenido">
                        <ckeditor [editor]="Editor" [config]="configCKEditor" formControlName="contenido"></ckeditor>
                        <div class="alert alert-danger" *ngIf="contenido.invalid && (contenido.dirty || contenido.touched)">
                            <div class="container-error-message" *ngIf="contenido.errors.required">
                                El contenido es requerido.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editSuscripcionForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>
