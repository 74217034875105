import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Suscripcion } from '../../../shared/modelos/suscripcion.modelo';
import { SuscripcionService } from '../suscripcion.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-suscripcion',
  templateUrl: './editar-suscripcion.component.html',
  styleUrls: ['./editar-suscripcion.component.scss']
})
export class EditarSuscripcionComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public suscripcion: Suscripcion;
  public tiposVideo =  [{id: 'ninguno', nombre: 'Sin video'}, {id: 'privado', nombre: 'Privado (vimeo)'}, {id: 'publico', nombre: 'Público'}];
  public Editor = ClassicEditor;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarSuscripcionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private suscSvc: SuscripcionService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.suscripcion = data.suscripcion;
  }

  public editSuscripcionForm = new FormGroup({
    id: new FormControl(''),
    codigo: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    subtitulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    contenido: new FormControl('', [Validators.required]),
    imagen: new FormControl(''),
    tipoVideo: new FormControl(''),
    video: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo === 'editar') {
      this.image = this.suscripcion.imagen;
      this.imageOriginal = this.suscripcion.imagen;
    }

    this.cargarValoresInicialesFormulario(this.suscripcion);
  }

  saveSuscripcion(event): void {
    if (!this.editSuscripcionForm.valid) {
      return;
    }

    const suscripcion = this.editSuscripcionForm.value;

    if (suscripcion.tipoVideo !== 'ninguno' && suscripcion.video === '') {
      Swal.fire('Error!', 'Debe agregar una URL de video o marcar que la suscripción no tiene video', 'error');
      return;
    }

    if (suscripcion.tipoVideo === 'ninguno') {
      suscripcion.video = '';
    }

    this.globals.loading = true;

    if (this.modo !== 'crear') {
      if (this.image === this.imageOriginal) {
        suscripcion.imagen = this.imageOriginal;
        this.suscSvc.update(suscripcion)
        .then(() => {
          this.sbSvc.success('Suscripción actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la suscripción', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.suscSvc.updateWithUpload(suscripcion, this.image)
        .then(() => {
          this.sbSvc.success('Suscripción actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la suscripción', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(suscripcion?: Suscripcion): void {
    if (suscripcion) {
      this.editSuscripcionForm.patchValue({
        id: suscripcion.id,
        titulo: suscripcion.titulo,
        subtitulo: suscripcion.subtitulo,
        codigo: suscripcion.codigo,
        contenido: suscripcion.contenido,
        tipoVideo: suscripcion.tipoVideo,
        video: suscripcion.video,
      });
    } else {
      this.editSuscripcionForm.patchValue({
        titulo: '',
        subtitulo: '',
        codigo: '',
        contenido: '',
        enlace: '',
        tipoVideo: '',
        video: ''
      });
    }
  }

  get titulo() { return this.editSuscripcionForm.get('titulo'); }
  get subtitulo() { return this.editSuscripcionForm.get('subtitulo'); }
  get codigo() { return this.editSuscripcionForm.get('codigo'); }
  get contenido() { return this.editSuscripcionForm.get('contenido'); }
  get tipoVideo() { return this.editSuscripcionForm.get('tipoVideo'); }
  get video() { return this.editSuscripcionForm.get('video'); }
}
