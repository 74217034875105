import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Post } from '../../shared/modelos/post.modelo';
import { DbService } from '../../shared/servicios/db.service';
import { CategoriaPostService } from '../categorias-post/categoriapost.service';
import { map, take } from 'rxjs/operators';
import { FileI } from 'src/app/shared/modelos/file.interface';

@Injectable({
  providedIn: 'root'
})
export class PostService extends DbService<Post> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage, private catPostSvc: CategoriaPostService) {
    super('posts', afs, storage);
  }

  async update(item: Post, imagen?: FileI, actualizarImagen: boolean = true): Promise<Post> {
    // 1º Si hay imagen, la subimos
    if (actualizarImagen) {
      item.imagen = await this.uploadImagen(imagen);
    }

    // 2º Realizamos el update
    return super.update(item);
  }
}
