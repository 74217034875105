import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { CategoriaReceta } from '../../shared/modelos/categoriareceta.modelo';
import { DbService } from '../../shared/servicios/db.service';
import { take, map } from 'rxjs/operators';
import { FileI } from 'src/app/shared/modelos/file.interface';
import { Receta } from 'src/app/shared/modelos/receta.modelo';

@Injectable({
  providedIn: 'root'
})
export class CategoriaRecetaService extends DbService<CategoriaReceta> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('categorias_receta', afs, storage);
  }

  public getTituloCategoriaReceta(idCategoriaReceta: string): Promise<string> {
    return this.afs.collection<CategoriaReceta>('categorias_receta').doc(idCategoriaReceta)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            return data.titulo;
          }
        }),
        take(1)
      ).toPromise();
  }

  private getRecetasByIdCategoriaReceta(idCategoriaReceta: string): Promise<string[]> {
    const coll = this.afs.collection('recetas', ref =>
      ref.where('idCategoria', '==', idCategoriaReceta)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items =>
        items.map( item => {
          return item.payload.doc.id;
        })
      ),
      take(1)
    ).toPromise();
  }

  async update(categoriaReceta: CategoriaReceta, imagen?: FileI, actualizarImagen: boolean = true): Promise<CategoriaReceta> {
    const promise = new Promise<CategoriaReceta>(async (resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();

      // 1º Comprobamos si el titulo se va a modificar
      const tituloActual = await this.getTituloCategoriaReceta(categoriaReceta.id);
      const cambioTitulo = (tituloActual !== categoriaReceta.titulo);

      // 2º Si hay cambio de título, obtenemos todas las recetas que habría que modificar
      let listaRecetas = [];
      if (cambioTitulo) {
        listaRecetas = await this.getRecetasByIdCategoriaReceta(categoriaReceta.id);
      }

      // 3º Si hay imagen, la subimos
      if (actualizarImagen) {
        categoriaReceta.imagen = await this.uploadImagen(imagen);
      }

      // 4º Modificamos la categoría de receta
      const idCategoriaReceta = categoriaReceta.id;
      const refCategoriaReceta = this.afs.collection('categorias_receta').doc<CategoriaReceta>(idCategoriaReceta).ref;
      delete categoriaReceta.id;
      batch.update(refCategoriaReceta, categoriaReceta);

      // 5º Actualizamos todas las recetas con el nuevo título (si ha habido cambios en dicho título)
      if (listaRecetas.length > 0) {
        listaRecetas.map(idReceta => {
          const refReceta = this.afs.collection('recetas').doc<Receta>(idReceta).ref;
          batch.update(refReceta, { tituloCategoria: categoriaReceta.titulo });
        });
      }

      batch.commit()
      .then(() => {
        // Devolvemos el ingrediente con su id
        resolve({
          id: idCategoriaReceta,
          ...(categoriaReceta as any)
        });
      })
      .catch(err => {
        reject(err);
      });
    });

    return promise;
  }

  private getNumRecetasByCategoria(idCategoria: string): Promise<number> {
    const coll = this.afs.collection('recetas', ref =>
      ref.where('idCategoria', '==', idCategoria)
    );

    return coll
    .snapshotChanges()
    .pipe(
      map(items =>
        items.length
      ),
      take(1)
    ).toPromise();
  }

  // Método delete con validación
  deleteValid(categoriaReceta: CategoriaReceta): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.getNumRecetasByCategoria(categoriaReceta.id)
      .then(numRecetas => {
        if (numRecetas === 0) {
          super.delete(categoriaReceta)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        } else {
          reject('Imposible eliminar una categoría de receta que tiene recetas asignadas');
        }
      })
      .catch(err => {
        reject(err);
      });
    });
    return promise;
  }
}
