import { Injectable, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, finalize, take, first } from 'rxjs/operators';
import { FileI } from '../../shared/modelos/file.interface';
import { IItemDb } from '../modelos/iitemdb.interface';
import { IDbService } from './idb.service';

@Injectable({
  providedIn: 'root'
})
export abstract class DbService<T extends IItemDb> implements IDbService<T> {

  protected coleccion: AngularFirestoreCollection<T>;
  protected path: string;

  constructor(@Inject(String) path: string, protected afs: AngularFirestore, private storage: AngularFireStorage) {
      this.path = path;
      this.coleccion = this.afs.collection(path);
  }

  getAll(): Observable<T[]> {
    return this.coleccion
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getAllOrden(campoOrden: string, direccionOrden: string): Observable<T[]> {
    return this.afs.collection(this.path, ref => ref.orderBy(campoOrden, direccionOrden as firebase.firestore.OrderByDirection))
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getOneSimple(identificador: string): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      return this.coleccion.doc(identificador).ref.get()
      .then(doc => {
        const data = doc.data() as any;
        const id = doc.id;
        resolve({ id, ...data });
      });
    });

    return promise;
  }

  getOne(identificador: string): Observable<T> {
    return this.coleccion
      .doc<T>(identificador)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            const id = doc.payload.id;
            return { id, ...data };
          }
        })
      );
  }

  // Método para añadir un elemento (sin upload)
  add(item: T): Promise<T> {
    // Eliminamos el id del item de sus propiedades
    delete item.id;

    const promise = new Promise<T>((resolve, reject) => {
      this.coleccion.add(item).then(ref => {
        const newItem = {
          id: ref.id,
          ...(item as any)
        };

        resolve(newItem);
      });
    });

    return promise;
  }

  // Método para añadir un elemento (con upload)
  addWithUpload(item: T, imagen?: FileI): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      if (imagen) {
        this.uploadImagen(imagen)
        .then(urlImagen => {
          // Una vez subida la imagen, añadimos la ruta de la imagen
          item.imagen = urlImagen;
          // Y la guardamos
          this.add(item)
          .then(newItem => {
            resolve(newItem);
          });
        }).catch(err => {
          reject(err);
        });
      } else {
        // Si no hay imagen, hacemos un add sin imagen
        this.add(item)
        .then(newItem => {
          resolve(newItem);
        });
      }
    });

    return promise;
  }

  update(item: T): Promise<T> {
    // Obtenemos el id del item y lo eliminamos de sus propiedades
    const idItem = item.id;
    delete item.id;

    const promise = new Promise<T>((resolve, reject) => {
      const docRef = this.coleccion
        .doc<T>(idItem)
        .update(item)
        .then(() => {
          resolve({
            ...(item as any)
          });
        })
        .catch(err => {
          reject(err);
        });
      });

    return promise;
  }

  updateWithUpload(item: T, imagen?: FileI): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      if (imagen) {
        this.uploadImagen(imagen)
        .then(urlImagen => {
          item.imagen = urlImagen;
          this.update(item)
          .then(updatedItem => {
            resolve(updatedItem);
          });
        })
        .catch(err => {
          reject(err);
        });
      } else {
        this.update(item)
        .then(updatedItem => {
          resolve(updatedItem);
        });
      }
    });

    return promise;
  }

  delete(item: T): Promise<void> {
    const docRef = this.coleccion.doc<T>(item.id);
    return docRef.delete();
  }

  protected uploadImagen(image: FileI): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const filePath = `${this.path}/${image.name}`;
      const fileRef = this.storage.ref(filePath);
      const sub = this.storage.upload(filePath, image)
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          try {
            const urlImagen = await fileRef.getDownloadURL().toPromise();
            resolve(urlImagen);
          } catch (err) {
            reject(err);
            sub.unsubscribe();
          }
        })
      ).subscribe();
    });

    return promise;
  }
}
