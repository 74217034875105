import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Receta } from '../../../shared/modelos/receta.modelo';
import { CategoriaReceta } from '../../../shared/modelos/categoriareceta.modelo';
import { RecetaService } from '../receta.service';
import { CategoriaRecetaService } from '../../categorias-receta/categoriareceta.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { firestore } from 'firebase';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-receta',
  templateUrl: './editar-receta.component.html',
  styleUrls: ['./editar-receta.component.scss']
})
export class EditarRecetaComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public categorias$: Observable<CategoriaReceta[]>;
  public tiposVideo =  [{id: 'ninguno', nombre: 'Sin video'}, {id: 'privado', nombre: 'Privado (vimeo)'}, {id: 'publico', nombre: 'Público'}];
  public nivelesAcceso =  [{id: 1, nombre: 'Gratis'}, {id: 2, nombre: 'Suscripción'}];
  public modo: string;
  public tituloForm: string;
  public receta: Receta;
  public dificultades: any[];
  public unidadesDuracion: any[];
  subscription: Subscription;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarRecetaComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private recetaSvc: RecetaService, private catRecetaSvc: CategoriaRecetaService) {
    this.globals = globals;
    this.dificultades = ['Fácil', 'Media', 'Alta'];
    this.unidadesDuracion = ['min.', 'horas'];
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.receta = data.receta;
  }

  public editRecetaForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(3)]),
    cantidad: new FormControl('', [Validators.required]),
    dificultad: new FormControl('', [Validators.required]),
    duracion: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(3)]),
    unidadDuracion: new FormControl('', [Validators.required]),
    fecha: new FormControl('', [Validators.required]),
    exclusiva: new FormControl(''),
    destacada: new FormControl(''),
    glutenFree: new FormControl(''),
    idCategoria: new FormControl('', [Validators.required]),
    imagen: new FormControl(''),
    tipoVideo: new FormControl(''),
    video: new FormControl(''),
    nivelAcceso: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    const controlTipoVideo = this.editRecetaForm.get('tipoVideo') as FormControl;
    const controlVideo = this.editRecetaForm.get('video') as FormControl;

    this.subscription = controlTipoVideo.valueChanges.subscribe(value => {
      if (value !== 'ninguno') {
        controlVideo.setValidators([Validators.required, Validators.pattern(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)]);
      } else {
        controlVideo.setValidators(null);
      }

      controlVideo.updateValueAndValidity();
    });

    this.categorias$ = this.catRecetaSvc.getAllOrden('titulo', 'asc');

    if (this.modo === 'editar') {
      this.image = this.receta.imagen;
      this.imageOriginal = this.receta.imagen;
    }

    this.cargarValoresInicialesFormulario(this.receta);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  saveReceta(event): void {
    if (!this.editRecetaForm.valid) {
      return;
    }

    const receta = this.editRecetaForm.value;

    if (receta.tipoVideo !== 'ninguno' && receta.video === '') {
      Swal.fire('Error!', 'Debe agregar una URL de video o marcar que la receta no tiene video', 'error');
      return;
    }

    receta.fecha = firestore.Timestamp.fromDate(moment(receta.fecha, 'YYYY-MM-DDTHH:mm').toDate());
    receta.duracion = +receta.duracion;
    if (receta.tipoVideo === 'ninguno') {
      receta.video = '';
    }
    if (receta.nivelAcceso === null) {
      receta.nivelAcceso = 1; // Si no tiene nivel de acceso, es gratis
    }

    if (receta.unidadDuracion === 'min.') {
      if (receta.duracion > 60) {
        receta.filtro_duracion = '3';
      } else if (receta.duracion <= 30) {
        receta.filtro_duracion = '1';
      } else {
        receta.filtro_duracion = '2';
      }
    } else if (receta.unidadDuracion === 'horas') {
      if (receta.duracion <= 1) {
        receta.filtro_duracion = '2';
      } else {
        receta.filtro_duracion = '3';
      }
    }

    this.globals.loading = true;

    if (this.modo === 'crear') {
      receta.resumen = receta.titulo;
      this.recetaSvc.addWithUpload(receta, this.image)
      .then(() => {
        this.sbSvc.success('Receta añadida correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir la receta', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        receta.imagen = this.imageOriginal;
      }

      this.recetaSvc.update(receta, this.image, this.image !== this.imageOriginal)
      .then(() => {
        this.sbSvc.success('Receta actualizada correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar la receta', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  trackElement(index: number, element: any) {
    return element ? element.id : null;
  }

  private cargarValoresInicialesFormulario(receta?: Receta): void {
    if (receta) {
      this.editRecetaForm.patchValue({
        id: receta.id,
        titulo: receta.titulo,
        cantidad: receta.cantidad,
        dificultad: receta.dificultad,
        duracion: receta.duracion,
        unidadDuracion: receta.unidadDuracion,
        fecha: moment(receta.fecha.toDate()).format('YYYY-MM-DDTHH:mm'),
        exclusiva: receta.exclusiva,
        destacada: receta.destacada,
        idCategoria: receta.idCategoria,
        tipoVideo: receta.tipoVideo,
        video: receta.video,
        nivelAcceso: receta.nivelAcceso,
        glutenFree: receta.glutenFree
      });
    } else {
      this.editRecetaForm.patchValue({
        titulo: '',
        cantidad: '',
        dificultad: '',
        duracion: '',
        unidadDuracion: '',
        fecha: '',
        exclusiva: false,
        destacada: false,
        idCategoria: '',
        tipoVideo: 'ninguno',
        video: '',
        nivelAcceso: '',
        glutenFree: false
      });
    }
  }

  get titulo() { return this.editRecetaForm.get('titulo'); }
  get cantidad() { return this.editRecetaForm.get('cantidad'); }
  get dificultad() { return this.editRecetaForm.get('dificultad'); }
  get duracion() { return this.editRecetaForm.get('duracion'); }
  get unidadDuracion() { return this.editRecetaForm.get('unidadDuracion'); }
  get fecha() { return this.editRecetaForm.get('fecha'); }
  get idCategoria() { return this.editRecetaForm.get('idCategoria'); }
  get tipoVideo() { return this.editRecetaForm.get('tipoVideo'); }
  get video() { return this.editRecetaForm.get('video'); }
  get nivelAcceso() { return this.editRecetaForm.get('nivelAcceso'); }
}
