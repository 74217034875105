import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { CategoriaIngrediente } from '../../../shared/modelos/categoriaingrediente.modelo';
import { CategoriaIngredienteService } from '../categoriaingrediente.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-categoria-ingrediente',
  templateUrl: './editar-categoria-ingrediente.component.html',
  styleUrls: ['./editar-categoria-ingrediente.component.scss']
})
export class EditarCategoriaIngredienteComponent implements OnInit {
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public categoriaIngrediente: CategoriaIngrediente;
  globals: Globals;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarCategoriaIngredienteComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private catIngrSvc: CategoriaIngredienteService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.categoriaIngrediente = data.categoriaIngrediente;
  }

  public editCategoriaIngredienteForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    orden: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.categoriaIngrediente.imagen;
      this.imageOriginal = this.categoriaIngrediente.imagen;
    }

    this.cargarValoresInicialesFormulario(this.categoriaIngrediente);
  }

  saveCategoriaIngrediente(event): void {
    if (!this.editCategoriaIngredienteForm.valid) {
      return;
    }

    const categoriaIngrediente = this.editCategoriaIngredienteForm.value;
    categoriaIngrediente.orden = +categoriaIngrediente.orden;

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.catIngrSvc.addWithUpload(categoriaIngrediente, this.image)
      .then(() => {
        this.sbSvc.success('Categoría de ingrediente añadida correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir la categoría de ingrediente', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        categoriaIngrediente.imagen = this.imageOriginal;
        this.catIngrSvc.update(categoriaIngrediente)
        .then(() => {
          this.sbSvc.success('Categoría de ingrediente actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la categoría de ingrediente', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.catIngrSvc.updateWithUpload(categoriaIngrediente, this.image)
        .then(() => {
          this.sbSvc.success('Categoría de ingrediente actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la categoría de ingrediente', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(categoriaIngrediente?: CategoriaIngrediente): void {
    if (categoriaIngrediente) {
      this.editCategoriaIngredienteForm.patchValue({
        id: this.categoriaIngrediente.id,
        nombre: this.categoriaIngrediente.nombre,
        orden: categoriaIngrediente.orden
      });
    } else {
      this.editCategoriaIngredienteForm.patchValue({
        nombre: '',
        orden: 0
      });
    }
  }

  get nombre() { return this.editCategoriaIngredienteForm.get('nombre'); }
  get orden() { return this.editCategoriaIngredienteForm.get('orden'); }
}
