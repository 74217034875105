import { Injectable } from '@angular/core';
import { UserI } from '../modelos/user.interface';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { FileI } from '../modelos/file.interface';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData$: Observable<firebase.User>;
  private filePath: string;

  constructor(private afAuth: AngularFireAuth, private storage: AngularFireStorage) {
    this.userData$ = afAuth.authState;
  }

  loginByEmail(user: UserI) {
    const {email, password } = user;
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  preSaveUserPerfil(user: UserI, image?: FileI): void {
    if (image) {
      this.uploadImage(user, image);
    } else {
      this.saveUserPerfil(user);
    }
  }

  private uploadImage(user: UserI, image: FileI) {
    this.filePath = `images/${image.name}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, image);
    task.snapshotChanges()
    .pipe(
      finalize( () => {
        fileRef.getDownloadURL().subscribe( urlImage => {
          user.photoURL = urlImage;
          this.saveUserPerfil(user);
        });
      })
    ).subscribe();
  }

  private saveUserPerfil(user: UserI) {
    this.afAuth.auth.currentUser.updateProfile({
      displayName: user.displayName,
      photoURL: user.photoURL
    })
    .then(() => {
      console.log('User updated!');
    })
    .catch( err => {
      console.log('Error', err);
    });
  }
}
