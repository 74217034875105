import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    public configSnackbarSuccess: MatSnackBarConfig;
    public configSnackbarError: MatSnackBarConfig;

    constructor(public snackBar: MatSnackBar, private zone: NgZone) {
        this.configSnackbarSuccess = new MatSnackBarConfig();
        this.configSnackbarSuccess.duration = 3000;
        this.configSnackbarSuccess.panelClass = ['snackbar-custom'];

        this.configSnackbarError = new MatSnackBarConfig();
        this.configSnackbarError.duration = 4000;
        this.configSnackbarError.panelClass = ['snackbar-custom-error'];
    }

    public success(message) {
        this.zone.run(() => {
          this.snackBar.open(message, 'OK', this.configSnackbarSuccess);
        });
    }

    public error(message) {
        this.zone.run(() => {
          this.snackBar.open(message, 'ERROR', this.configSnackbarError);
        });
    }
}
