import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContenedorAppComponent } from './componentes/principal/contenedor-app/contenedor-app.component';
import { PostComponent } from './componentes/paginas/post/post.component';
import { Pagina404Component } from './componentes/principal/pagina-404/pagina-404.component';

const routes: Routes = [
  {
    path: '', component: ContenedorAppComponent, children: [
      { path: 'home', loadChildren: () => import('./componentes/paginas/home/home.module').then(m => m.HomeModule) },
      { path: 'post/:id', component: PostComponent },
      { path: 'acerca', loadChildren: () => import('./componentes/paginas/acerca/acerca.module').then(m => m.AcercaModule) },
      { path: '', redirectTo: 'home', pathMatch: 'full'}
    ]
  },
  { path: 'admin', loadChildren: () => import('./componentes/admin/admin.module').then(m => m.AdminModule) },
  { path: 'login', loadChildren: () => import('./componentes/auth/login/login.module').then(m => m.LoginModule) },
  { path: '404', component: Pagina404Component},
  { path: '**', redirectTo: '/404'}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
