<div class="container" *ngIf="post$ | async as post; else loading">
    <mat-card class="post-card">
        <img matCardImage class="imagen_post" [src]="post.imagen">
        <mat-card-content>
            <mat-card-title>
                {{post.titulo}}
            </mat-card-title>
            <mat-card-subtitle>
                {{post.subtitulo}}
            </mat-card-subtitle>
            <mat-divider></mat-divider>
            <p class="content" [innerHTML]="post.contenido">
            </p>
        </mat-card-content>
    </mat-card>
    <div class="container-button">
        <button mat-raised-button class="boton-volver" (click)="volver()">
            Volver
        </button>
    </div>
</div>
<ng-template #loading>
    <div class="container-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
