import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { PasoElaboracion } from '../../shared/modelos/pasoelaboracion.modelo';
import { Observable } from 'rxjs';
import { map, take, finalize } from 'rxjs/operators';
import { FileI } from 'src/app/shared/modelos/file.interface';

@Injectable({
  providedIn: 'root'
})
export class ElaboracionService {
  constructor(protected afs: AngularFirestore, private storage: AngularFireStorage) {

  }

  getAllPasosByReceta(idReceta: string): Observable<PasoElaboracion[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('elaboracion', ref => ref.orderBy('orden'))
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as PasoElaboracion;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  updateElaboracionReceta(idReceta: string, pasos: PasoElaboracion[]): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();
      this.getAllPasosByRecetaPromise(idReceta)
      .then(idsPasos => {
        // 1.- Eliminamos todos los pasos de la elaboración de la receta (subcolección)
        idsPasos.map(idPaso => {
          const refElabRecetaSub = this.afs.collection('recetas').doc(idReceta).collection('elaboracion').doc(idPaso).ref;
          batch.delete(refElabRecetaSub);
        });

        // 2.- Agregamos todos los pasos en la subcolección
        let orden = 1;
        let contadorPasos = 1;

        for (const paso of pasos) {
          const id = this.afs.createId();
          const refPaso = this.afs.collection('recetas').doc(idReceta).collection('elaboracion').doc(id).ref;
          const elaboracionObject = Object.assign({}, paso);
          elaboracionObject.orden = orden;
          if (paso.tipo === 'paso') {
            elaboracionObject.numero = contadorPasos;
            contadorPasos++;
          }
          delete elaboracionObject.id;
          batch.set(refPaso, elaboracionObject);
          orden++;
        }

        batch.commit()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
      });
    });

    return promise;
  }

  update(idReceta: string, item: PasoElaboracion): Promise<PasoElaboracion> {
    // Obtenemos el id del item y lo eliminamos de sus propiedades
    const idItem = item.id;
    delete item.id;
    if (item.imagen === undefined) {
      item.imagen = '';
    }
    const promise = new Promise<PasoElaboracion>((resolve, reject) => {
      const docRef = this.afs.collection('recetas').doc(idReceta).collection('elaboracion')
        .doc<PasoElaboracion>(idItem)
        .update(item)
        .then(() => {
          resolve({
            ...(item as any)
          });
        });
      });

    return promise;
  }

  updateWithUpload(idReceta: string, item: PasoElaboracion, imagen?: FileI): Promise<PasoElaboracion> {
    const promise = new Promise<PasoElaboracion>((resolve, reject) => {
      if (imagen) {
        this.uploadImagen(idReceta, imagen)
        .then(urlImagen => {
          item.imagen = urlImagen;
          this.update(idReceta, item)
          .then(updatedItem => {
            resolve(updatedItem);
          });
        });
      } else {
        this.update(idReceta, item)
        .then(updatedItem => {
          resolve(updatedItem);
        });
      }
    });

    return promise;
  }

  /* Privados */
  private getAllPasosByRecetaPromise(idReceta: string): Promise<string[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('elaboracion')
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          return a.payload.doc.id;
        })
      ),
      take(1)
    ).toPromise();
  }

  private uploadImagen(idReceta: string, image: FileI): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const filePath = `recetas/${idReceta}/pasos/${image.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image);
      task.snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe( urlImagen => {
            resolve(urlImagen);
          });
        })
      ).subscribe();
    });
    return promise;
  }
}
