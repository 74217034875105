<div class="container-modal">
    <div class="container-modal-titulo">
        <h1>{{tituloForm}}</h1>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="container-modal-cerrar">
        <button mat-raised-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialog-content">
    <div>
        <div class="edit-container">
            <form [formGroup]="editRecetaForm" (ngSubmit)="saveReceta($event)">
                <div class="container-item">
                    <div class="container-item-1">
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Título</mat-label>
                            <input matInput placeholder="Título de la receta" formControlName="titulo" />
                            <div class="alert alert-danger" *ngIf="titulo.invalid && (titulo.dirty || titulo.touched)">
                                <div class="container-error-message" *ngIf="titulo.errors.required">
                                    El nombre es requerido.
                                </div>
                                <div class="container-error-message" *ngIf="titulo.errors.minlength">
                                    El nombre debe tener, al menos, 3 caracteres.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-label>Cantidad</mat-label>
                            <input matInput placeholder="Cantidad de la receta" formControlName="cantidad" />
                            <div class="alert alert-danger" *ngIf="cantidad.invalid && (cantidad.dirty || cantidad.touched)">
                                <div class="container-error-message" *ngIf="cantidad.errors.required">
                                    El campo cantidad es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-half">
                            <mat-select placeholder="Dificultad de la receta" formControlName="dificultad">
                                <mat-option *ngFor="let dificultad of dificultades" [value]="dificultad">{{dificultad}}</mat-option>
                            </mat-select>
                            <div class="alert alert-danger" *ngIf="dificultad.invalid && (dificultad.dirty || dificultad.touched)">
                                <div class="container-error-message" *ngIf="dificultad.errors.required">
                                    El campo dificultad es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-halfl">
                            <mat-select placeholder="Nivel de acceso" formControlName="nivelAcceso">
                                <mat-option *ngFor="let nivelAcceso of nivelesAcceso" [value]="nivelAcceso.id">{{nivelAcceso.nombre}}</mat-option>
                            </mat-select>
                            <div class="alert alert-danger" *ngIf="nivelAcceso.invalid && (nivelAcceso.dirty || nivelAcceso.touched)">
                                <div class="container-error-message" *ngIf="nivelAcceso.errors.required">
                                    El campo nivel es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full-half">
                            <mat-label>Duración</mat-label>
                            <input matInput placeholder="Duración de la receta" formControlName="duracion" />
                            <div class="alert alert-danger" *ngIf="duracion.invalid && (duracion.dirty || duracion.touched)">
                                <div class="container-error-message" *ngIf="duracion.errors.required">
                                    El campo duración es requerido.
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="duracion.invalid && (duracion.dirty || duracion.touched)">
                                <div class="container-error-message" *ngIf="duracion.errors.pattern">
                                    El campo duración debe ser numérico sin decimales.
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="duracion.invalid && (duracion.dirty || duracion.touched)">
                                <div class="container-error-message" *ngIf="duracion.errors.maxlength">
                                    El campo duración no puede ser mayor que 999.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-ful-halfl">
                            <mat-select placeholder="Unidad de tiempo" formControlName="unidadDuracion">
                                <mat-option *ngFor="let unidadDuracion of unidadesDuracion" [value]="unidadDuracion">{{unidadDuracion}}</mat-option>
                            </mat-select>
                            <div class="alert alert-danger" *ngIf="unidadDuracion.invalid && (unidadDuracion.dirty || unidadDuracion.touched)">
                                <div class="container-error-message" *ngIf="unidadDuracion.errors.required">
                                    El campo unidad de duración es requerido.
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-full">
                            <mat-select placeholder="Categoria" formControlName="idCategoria">
                                <mat-option *ngFor="let categoria of categorias$ | async" [value]="categoria.id">{{categoria.titulo}}</mat-option>
                            </mat-select>
                            <div class="alert alert-danger" *ngIf="idCategoria.invalid && (idCategoria.dirty || idCategoria.touched)">
                                <div class="container-error-message" *ngIf="idCategoria.errors.required">
                                    La categoría es requerida.
                                </div>
                            </div>
                        </mat-form-field>
                        <div class="contenedor-inline">
                            <mat-form-field class="mat-form-field-one-third">
                                <mat-select placeholder="Tipo de video" formControlName="tipoVideo">
                                    <mat-option *ngFor="let tipoVideo of tiposVideo" [value]="tipoVideo.id">{{tipoVideo.nombre}}</mat-option>
                                </mat-select>
                                <div class="alert alert-danger" *ngIf="tipoVideo.invalid && (tipoVideo.dirty || tipoVideo.touched)">
                                    <div class="container-error-message" *ngIf="tipoVideo.errors.required">
                                        El campo tipo de video es requerido.
                                    </div>
                                </div>
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-resto" *ngIf="tipoVideo.value && tipoVideo.value != 'ninguno'">
                                <mat-label>Video (URL)</mat-label>
                                <input matInput placeholder="Video de la receta" formControlName="video" />
                                <div class="alert alert-danger" *ngIf="video.invalid && (video.dirty || video.touched)">
                                    <div class="container-error-message" *ngIf="video.errors.pattern">
                                        La URL del video no es válida.
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>                            
                        <mat-form-field class="mat-form-field-full">
                            <input matInput type="datetime-local" placeholder="Fecha de publicación" formControlName="fecha">
                        </mat-form-field>
                        <div class="checkbox-formulario">
                            <mat-checkbox class="example-margin" formControlName="glutenFree">Gluten Free</mat-checkbox>
                        </div>
                        <div class="checkbox-formulario">
                            <mat-checkbox class="example-margin" formControlName="exclusiva">¿Receta exclusiva de la app?</mat-checkbox>
                        </div>
                        <div class="checkbox-formulario">
                            <mat-checkbox class="example-margin" formControlName="destacada" disabled *ngIf="modo === 'editar'">¿Es la receta destacada de la app?</mat-checkbox>
                        </div>
                    </div>
                    <div class="container-item-2">
                        <div class="label bottom">
                            <p>{{modo == 'crear' ? 'Agregar imagen' : 'Modificar imagen' }}</p>
                            <div class="contenedor-imagen-item">
                                <img [src]="imagePreview ? imagePreview : image" (click)="inputImageReceta.click()" *ngIf="imagePreview || image" />
                                <img src="../../../../assets/no_imagen.jpg" (click)="inputImageReceta.click()" *ngIf="!(imagePreview || image)" />
                            </div>
                            <input type="file" #inputImageReceta formControlName="imagen" (change)="handleImagen($event)" />
                        </div>
                    </div>
                </div>
                <div class="container-botones">
                    <input type="submit" class="mat-raised-button" value="Guardar" [disabled]='!editRecetaForm.valid'>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<app-spinner></app-spinner>
