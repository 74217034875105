import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { CategoriaPost } from '../../../shared/modelos/categoriapost.modelo';
import { CategoriaPostService } from '../categoriapost.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-categoria-post',
  templateUrl: './editar-categoria-post.component.html',
  styleUrls: ['./editar-categoria-post.component.scss']
})
export class EditarCategoriaPostComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;

  public modo: string;
  public tituloForm: string;
  public categoriaPost: CategoriaPost;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarCategoriaPostComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private catPostSvc: CategoriaPostService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.categoriaPost = data.categoriaPost;
  }

  public editCategoriaPostForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(3)]),
    orden: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.categoriaPost.imagen;
      this.imageOriginal = this.categoriaPost.imagen;
    }

    this.cargarValoresInicialesFormulario(this.categoriaPost);
  }

  saveCategoriaPost(event): void {
    if (!this.editCategoriaPostForm.valid) {
      return;
    }

    const categoriaPost = this.editCategoriaPostForm.value;
    categoriaPost.orden = +categoriaPost.orden;

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.catPostSvc.addWithUpload(categoriaPost, this.image)
      .then(() => {
        this.sbSvc.success('Categoría de post añadida correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir la categoría de post', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        categoriaPost.imagen = this.imageOriginal;
        this.catPostSvc.update(categoriaPost)
        .then(() => {
          this.sbSvc.success('Categoría de post actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la categoría de post', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.catPostSvc.updateWithUpload(categoriaPost, this.image)
        .then(() => {
          this.sbSvc.success('Categoría de post actualizada correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar la categoría de post', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(categoriaPost?: CategoriaPost): void {
    if (categoriaPost) {
      this.editCategoriaPostForm.patchValue({
        id: categoriaPost.id,
        titulo: categoriaPost.titulo,
        orden: categoriaPost.orden
      });
    } else {
      this.editCategoriaPostForm.patchValue({
        titulo: '',
        orden: 0
      });
    }
  }

  get titulo() { return this.editCategoriaPostForm.get('titulo'); }
  get orden() { return this.editCategoriaPostForm.get('orden'); }
}
