import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Pack } from '../../shared/modelos/pack.modelo';
import { DbService } from '../../shared/servicios/db.service';

@Injectable({
  providedIn: 'root'
})
export class PackService extends DbService<Pack> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('packs', afs, storage);
  }
}
