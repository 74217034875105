import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utensilio } from '../../shared/modelos/utensilio.modelo';
import { Observable } from 'rxjs';
import { map, take, finalize } from 'rxjs/operators';
import { FileI } from 'src/app/shared/modelos/file.interface';

@Injectable({
  providedIn: 'root'
})
export class UtensilioService {
  constructor(protected afs: AngularFirestore, private storage: AngularFireStorage) {
  }

  getAllItemsByReceta(idReceta: string): Observable<any[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('utensilios', ref => ref.orderBy('orden'))
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  update(idReceta: string, item: Utensilio): Promise<Utensilio> {
    // Obtenemos el id del item y lo eliminamos de sus propiedades
    const idItem = item.id;
    delete item.id;
    if (item.imagen === undefined) {
      item.imagen = '';
    }
    const promise = new Promise<Utensilio>((resolve, reject) => {
      const docRef = this.afs.collection('recetas').doc(idReceta).collection('utensilios')
        .doc<Utensilio>(idItem)
        .update(item)
        .then(() => {
          resolve({
            ...(item as any)
          });
        });
      });

    return promise;
  }

  updateWithUpload(idReceta: string, item: Utensilio, imagen?: FileI): Promise<Utensilio> {
    const promise = new Promise<Utensilio>((resolve, reject) => {
      if (imagen) {
        this.uploadImagen(idReceta, imagen)
        .then(urlImagen => {
          item.imagen = urlImagen;
          this.update(idReceta, item)
          .then(updatedItem => {
            resolve(updatedItem);
          });
        });
      } else {
        this.update(idReceta, item)
        .then(updatedItem => {
          resolve(updatedItem);
        });
      }
    });

    return promise;
  }

  updateItemsReceta(idReceta: string, items: any[]): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      // Iniciamos el batch
      const batch = this.afs.firestore.batch();
      this.getAllItemsByRecetaPromise(idReceta)
      .then(idsItems => {
        // 1.- Eliminamos todos los utensilios de la receta (subcolección)
        idsItems.map(idItem => {
          const refUtRecetaSub = this.afs.collection('recetas').doc(idReceta).collection('utensilios').doc(idItem).ref;
          batch.delete(refUtRecetaSub);
        });

        // 2.- Agregamos todos los utensilios en la subcolección
        let orden = 1;

        for (const item of items) {
          const id = this.afs.createId();
          const refUtensilio = this.afs.collection('recetas').doc(idReceta).collection('utensilios').doc(id).ref;
          const utensilioObject = Object.assign({}, item);
          utensilioObject.orden = orden;
          delete utensilioObject.id;
          batch.set(refUtensilio, utensilioObject);
          orden++;
        }

        batch.commit()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
      });
    });

    return promise;
  }

  /* Privados */
  private getAllItemsByRecetaPromise(idReceta: string): Promise<string[]> {
    return this.afs.collection('recetas').doc(idReceta).collection('utensilios')
    .snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => {
          return a.payload.doc.id;
        })
      ),
      take(1)
    ).toPromise();
  }

  private uploadImagen(idReceta: string, image: FileI): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const filePath = `recetas/${idReceta}/utensilios/${image.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image);
      task.snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe( urlImagen => {
            resolve(urlImagen);
          });
        })
      ).subscribe();
    });
    return promise;
  }
}
