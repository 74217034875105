import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Ingrediente } from '../../../shared/modelos/ingrediente.modelo';
import { CategoriaIngrediente } from '../../../shared/modelos/categoriaingrediente.modelo';
import { IngredienteService } from '../ingrediente.service';
import { CategoriaIngredienteService } from '../../categorias-ingrediente/categoriaingrediente.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-ingrediente',
  templateUrl: './editar-ingrediente.component.html',
  styleUrls: ['./editar-ingrediente.component.scss']
})
export class EditarIngredienteComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public categorias$: Observable<CategoriaIngrediente[]>;
  public modo: string;
  public tituloForm: string;
  public ingrediente: Ingrediente;
  public Editor = ClassicEditor;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarIngredienteComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private ingrSvc: IngredienteService, private catIngrSvc: CategoriaIngredienteService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.ingrediente = data.ingrediente;
  }

  public editIngredienteForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    basico: new FormControl(''),
    contenido: new FormControl(''),
    idCategoria: new FormControl('', [Validators.required]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    this.categorias$ = this.catIngrSvc.getAllOrden('nombre', 'asc');

    if (this.modo === 'editar') {
      this.image = this.ingrediente.imagen;
      this.imageOriginal = this.ingrediente.imagen;
    }

    this.cargarValoresInicialesFormulario(this.ingrediente);
  }

  saveIngrediente(event): void {
    if (!this.editIngredienteForm.valid) {
      return;
    }

    const ingrediente = this.editIngredienteForm.value;
    if (!ingrediente.contenido) {
      ingrediente.contenido = '';
    }

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.ingrSvc.addWithUpload(ingrediente, this.image)
      .then(() => {
        this.sbSvc.success('Ingrediente añadido correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        if (err.code === 'permission-denied') {
          Swal.fire('Error!', 'Ya existe un ingrediente con ese mismo nombre', 'error');
        } else {
          Swal.fire('Error!', err.message, 'error');
        }
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        ingrediente.imagen = this.imageOriginal;
      }

      this.ingrSvc.update(ingrediente, this.image, this.image !== this.imageOriginal)
      .then(() => {
        this.sbSvc.success('Ingrediente actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        if (err.code === 'permission-denied') {
          Swal.fire('Error!', 'Ya existe un ingrediente con ese mismo nombre', 'error');
        } else {
          Swal.fire('Error!', err.message, 'error');
        }
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(ingrediente?: Ingrediente): void {
    if (ingrediente) {
      this.editIngredienteForm.patchValue({
        id: ingrediente.id,
        nombre: ingrediente.nombre,
        basico: ingrediente.basico,
        contenido: ingrediente.contenido,
        idCategoria: ingrediente.idCategoria
      });
    } else {
      this.editIngredienteForm.patchValue({
        nombre: '',
        basico: false,
        contenido: '',
        idCategoria: ''
      });
    }
  }

  get nombre() { return this.editIngredienteForm.get('nombre'); }
  get basico() { return this.editIngredienteForm.get('basico'); }
  get idCategoria() { return this.editIngredienteForm.get('idCategoria'); }
  get contenido() { return this.editIngredienteForm.get('contenido'); }
}
