import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { Libro } from '../../../shared/modelos/libro.modelo';
import { LibroService } from '../libro.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-editar-libro',
  templateUrl: './editar-libro.component.html',
  styleUrls: ['./editar-libro.component.scss']
})
export class EditarLibroComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public libro: Libro;
  public Editor = ClassicEditor;
  public configCKEditor = {
    height: '100px',
    toolbar: [ 'heading', 'bold', 'italic', '|', 'link', '|', 'undo', 'redo' ]
  };

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarLibroComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private libroSvc: LibroService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.libro = data.libro;
  }

  public editLibroForm = new FormGroup({
    id: new FormControl(''),
    titulo: new FormControl('', [Validators.required, Validators.minLength(10)]),
    enlace: new FormControl('', [Validators.required, Validators.pattern(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)]),
    contenido: new FormControl('', [Validators.required]),
    precio: new FormControl('', [Validators.required, Validators.maxLength(7), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
    orden: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo === 'editar') {
      this.image = this.libro.imagen;
      this.imageOriginal = this.libro.imagen;
    }

    this.cargarValoresInicialesFormulario(this.libro);
  }

  saveLibro(event): void {
    if (!this.editLibroForm.valid) {
      return;
    }

    const libro = this.editLibroForm.value;
    libro.precio = +libro.precio;
    libro.orden = +libro.orden;

    this.globals.loading = true;

    if (this.modo === 'crear') {
      this.libroSvc.addWithUpload(libro, this.image)
      .then(() => {
        this.sbSvc.success('Libro añadido correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al añadir el libro', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      if (this.image === this.imageOriginal) {
        libro.imagen = this.imageOriginal;
        this.libroSvc.update(libro)
        .then(() => {
          this.sbSvc.success('Libro actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el libro', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      } else {
        this.libroSvc.updateWithUpload(libro, this.image)
        .then(() => {
          this.sbSvc.success('Libro actualizado correctamente!');
          this.dialog.close();
        })
        .catch(err => {
          Swal.fire('Error!', 'Se produjo un error al actualizar el libro', 'error');
        })
        .finally(() => {
          this.globals.loading = false;
        });
      }
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(libro?: Libro): void {
    if (libro) {
      this.editLibroForm.patchValue({
        id: libro.id,
        titulo: libro.titulo,
        enlace: libro.enlace,
        contenido: libro.contenido,
        precio: libro.precio,
        orden: libro.orden
      });
    } else {
      this.editLibroForm.patchValue({
        titulo: '',
        enlace: '',
        contenido: '',
        precio: '',
        orden: 0
      });
    }
  }

  get titulo() { return this.editLibroForm.get('titulo'); }
  get enlace() { return this.editLibroForm.get('enlace'); }
  get precio() { return this.editLibroForm.get('precio'); }
  get orden() { return this.editLibroForm.get('orden'); }
  get contenido() { return this.editLibroForm.get('contenido'); }

}
