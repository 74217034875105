import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Evento } from '../../shared/modelos/evento.modelo';
import { DbService } from '../../shared/servicios/db.service';

@Injectable({
  providedIn: 'root'
})
export class EventoService extends DbService<Evento> {
  constructor(afs: AngularFirestore, storage: AngularFireStorage) {
    super('eventos', afs, storage);
  }
}
