import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  loading = false;
  maxFileSize = 1 * 1024 * 1024;
  maxFileSizeError = 'El tamaño de la imagen es superior a 1 Mb.';
}
