import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SpinnerModule } from '../../admin/spinner/spinner.module';
import { EditarPostComponent } from './editar-post.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EditarPostComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    CKEditorModule,
    SpinnerModule
  ]
})
export class EditarPostModule { }
