import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl,  Validators} from '@angular/forms';
import { UtensilioService } from '../utensilio.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../shared/servicios/snackbar.service';
import { Globals } from 'src/app/globals';
import { Utensilio } from '../../../shared/modelos/utensilio.modelo';

@Component({
  selector: 'app-editar-utensilio',
  templateUrl: './editar-utensilio.component.html',
  styleUrls: ['./editar-utensilio.component.scss']
})
export class EditarUtensilioComponent implements OnInit {
  globals: Globals;
  public image: any;
  private imageOriginal: any;
  public imagePreview: any;
  public modo: string;
  public tituloForm: string;
  public utensilio: Utensilio;
  public idReceta: string;
  public bQuitarImagen = false;

  constructor(globals: Globals, private sbSvc: SnackbarService,
              private dialog: MatDialogRef<EditarUtensilioComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private utenSvc: UtensilioService) {
    this.globals = globals;
    this.modo = data.modo;
    this.tituloForm = data.tituloForm;
    this.utensilio = data.utensilio;
    this.idReceta = data.idReceta;
  }

  public editUtensilioForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    imagen: new FormControl('')
  });

  ngOnInit(): void {
    if (this.modo !== 'crear') {
      this.image = this.utensilio.imagen;
      this.imageOriginal = this.utensilio.imagen;
    }

    this.cargarValoresInicialesFormulario(this.utensilio);
  }

  onQuitarImagen(): void {
    this.bQuitarImagen = true;
    this.image = null;
    this.imagePreview = null;
  }

  saveUtensilio(event): void {
    if (!this.editUtensilioForm.valid) {
      return;
    }

    const utensilio = this.editUtensilioForm.value;

    this.globals.loading = true;

    if (this.image === this.imageOriginal) {
      utensilio.imagen = this.imageOriginal;

      this.utenSvc.update(this.idReceta, utensilio)
      .then(() => {
        this.sbSvc.success('Utensilio actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar el utensilio', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    } else {
      this.utenSvc.updateWithUpload(this.idReceta, utensilio, this.image)
      .then(() => {
        this.sbSvc.success('Utensilio actualizado correctamente!');
        this.dialog.close();
      })
      .catch(err => {
        Swal.fire('Error!', 'Se produjo un error al actualizar el utensilio', 'error');
      })
      .finally(() => {
        this.globals.loading = false;
      });
    }
  }

  handleImagen(event: any): void {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    const sizeFile = event.target.files[0].size;

    if (mimeType.match(/image\/*/) == null) {
      Swal.fire('Error', 'El fichero que ha seleccionado no es una imagen', 'error');
      return;
    }

    if (sizeFile > this.globals.maxFileSize) {
      Swal.fire('Error', this.globals.maxFileSizeError, 'error');
      return;
    }

    const reader = new FileReader();
    this.image = event.target.files[0];

    reader.readAsDataURL(this.image);
    reader.onload = (event2) => {
      this.imagePreview = reader.result;
    };
  }

  private cargarValoresInicialesFormulario(utensilio?: Utensilio): void {
    if (utensilio) {
      this.editUtensilioForm.patchValue({
        id: utensilio.id,
        nombre: utensilio.nombre
      });
    } else {
      this.editUtensilioForm.patchValue({
        nombre: ''
      });
    }
  }

  get nombre() { return this.editUtensilioForm.get('nombre'); }
}
